



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getBrandKey, setFaviconByBrand } from '@/shared/helpers'

@Component
export default class ConnectionBox extends Vue {
    private apiUrl = '';
    private apiKey = '';
    private apiUrlError: string|null = null;
    private apiKeyError: string|null = null;
    private brand = process.env.VUE_APP_BRAND_KEY;

    @Prop({ default: '' })
    private readonly initialValue!: string;

    @Prop({ default: false })
    private readonly loading!: boolean;

    public mounted () {
        this.$nextTick(() => {
            if (this.initialValue.length > 0) {
                this.apiUrl = this.initialValue
                this.brand = getBrandKey(this.apiUrl)
                setFaviconByBrand(this.brand)
            }
        })
    }

    private async continue (): Promise<void> {
        this.apiUrlError = null
        this.apiKeyError = null

        if (this.apiUrl.length === 0) {
            this.apiUrlError = this.$t('connect.emptyApiUrlError', { brand: this.$t(`brand.${this.brand}`) }).toString()
        }

        if (this.apiKey.length === 0) {
            this.apiKeyError = this.$t('connect.emptyApiKeyError').toString()
        }

        if (!this.apiUrlError && !this.apiKeyError) {
            this.$emit('continue', { apiUrl: this.apiUrl, apiKey: this.apiKey })
        }
    }
}
