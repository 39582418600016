







































import { Component, Mixins } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import ConnectionBox from '@/modules/connect/components/ConnectionBox.vue'
import Connection from '@/api/models/Connection'
import ErrorMixin from '@/shared/mixins/error'
import { RawLocation } from 'vue-router'
import { getAuthToken } from '@/api/service'
import { RootStateMutations } from '@/store/mutations'

@Component({
    components: {
        ConnectionBox
    }
})
export default class Connect extends Mixins(ErrorMixin) {
    private loading = false
    private error = ''
    private initialApiKey = ''

    @Getter('connection')
    connection?: Connection

    @Getter('isLoading')
    readonly loadingGlobal!: boolean

    @Action('loadConnection')
    loadConnection!: () => void

    @Action('signIn')
    signIn!: (connection: Connection) => Promise<boolean>

    @Mutation(RootStateMutations.setLoading)
    setLoading!: (val: boolean) => void

    async created (): Promise<void> {
        if (this.connection != null && this.connection.apiUrl.length > 0) {
            return
        }

        if (await this.fetchConnection()) {
            await this.$router.push({ name: 'accounts' })
        }
        this.setLoading(false)
    }

    mounted () {
        if (getAuthToken() !== '') {
            this.setLoading(true)
        }

        if (location.search !== '') {
            const initialApiKey = (new URLSearchParams(location.search)).get('account') || ''

            if (initialApiKey.length > 0) {
                this.initialApiKey = initialApiKey
            }
        }
    }

    async fetchConnection (): Promise<boolean> {
        try {
            this.loading = true
            await this.loadConnection()
        } catch (e) {
            return false
        } finally {
            this.loading = false
        }

        return true
    }

    public async continue (connection: Connection) {
        this.loading = true

        try {
            await this.signIn(connection)

            if (this.connection) {
                const route: RawLocation = { name: 'accounts' }
                await this.$router.push(route)
            }
        } catch (e) {
            this.error = this.getErrorText(e, 'connect.connectError')
        } finally {
            this.loading = false
        }
    }

    private closeAlert () {
        this.error = ''
    }
}
